/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Los mensajes privados en GNU social son una de las cosas más olvidadas de la plataforma y este olvido viene dado por varios motivos; el primero que los mensajes privados o directos están implementados en un plugin que hay que añadir a posta en el config.php de la siguiente forma:"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">addPlugin('DirectMessage');</code>"
    }
  })), "\n", React.createElement(_components.p, null, "Después que la interfaz de los mensajes es bastante espartana y anticuada, usa esa separación entre la bandeja de entrada y de salida tan anticuada en lugar de la ventana normal de chat."), "\n", React.createElement(_components.p, null, "El tercer motivo y uno de los más importantes es que los mensajes no federan, OStatus no está preparado para ello y la gente que conoce el protocolo en profundidad asegura que no es la mejor solución."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://xmpp.org/"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 600px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 100%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA3XAAAN1wFCKJt4AAACOElEQVQ4y6WSTWgTQRTH91YQL3rx2GvwqheFHoReSjB6UEkC0YiQ0KRCkkNAEqzEEIQUCkYRKhTWHEQasCR+pKQHE1DTaCp+pLSxa0sIpEabpNruZHfnzcjumjZfm0v/DMPw3vvNvHnvMbQloqzB6gpgut2EAIEeiFDA8q4FN/7yXOnngGclCa9uVEQJd8BEubKy3Thx8a7tYaRYS/PiTvtXxMp3WHxkv3n7rHtGtZADuJXcyOT8Ufv1exn9uxK7fymltB4x/jIOnRq7NvHsA6UUA3SkjUGOiy5XhnwPfK8vpNbD0CJBQPU7Z9auDp/0Pclv7cqWlovpKuPofPEceyP2xdZAVdXCF7NN+/Gp8cvupWo72VEw5W2ytkdPz73wvhpbqcZlI6VN1vHp0jH387d12Q9Eq1WqJ1/F56OB6fSVP7ROvi5yxiMzkelNqb0IGn1Ws+Jq6NbC1Oz7ic3JkaXZ+9sq2dM8prefAECFvd9v5l4+Hn+64FttfJMAQR+2E5bHS24DQevLzR+fKcAuoJWtjxu1osoSRf0KBiAIAqUUIaRG/N8xASwfhKYgiqLmyyzLhkKhRCLhdDiSyWQwGPT7ZHk8nkAg4PV6nU5nuVzev5c5+Cel4XBYp9NFo1GbzabX6w0Gg8ViMZlMfr/farWazWaXy1UqlbphNdt4PM5xXCwWy+fz6XQ6l8ulUqlMJgMAhUIhm81ijAcVjOd59SApkicXY4QQVoQQAgBNuM8oaIuhh9Ch4H90szrrZc/CbQAAAABJRU5ErkJggg=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"XMPP_logo\"\n        title=\"XMPP_logo\"\n        src=\"/static/243080e8b3ff69390f0f03ff222f237f/0a47e/XMPP_logo.png\"\n        srcset=\"/static/243080e8b3ff69390f0f03ff222f237f/c26ae/XMPP_logo.png 158w,\n/static/243080e8b3ff69390f0f03ff222f237f/6bdcf/XMPP_logo.png 315w,\n/static/243080e8b3ff69390f0f03ff222f237f/0a47e/XMPP_logo.png 600w\"\n        sizes=\"(max-width: 600px) 100vw, 600px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), "\n", React.createElement(_components.p, null, "¿Cuál es en mi opinión la mejor solución para esto? Implementar XMPP para los mensajes, esta solución permitiría federar mensajes al igual que cualquier servidor con XMPP, usar la cuenta con usuarios fuera de GNU social que tengan estas cuentas y también solucionaría el segundo problema porque se podrían usar aplicaciones XMPP como Conversation para iniciar conversaciones con toda tu red federada."), "\n", React.createElement(_components.p, null, "Puesto que ya hay un plugin para GNU social de XMPP, que permite usar el protocolo para publicar en la red, la solución podría ser modificar el plugin para que publique y reciba directamente desde los mensajes directos. Espero que este post sirva para iniciar una conversación sobre este tema y si alguien con conocimientos para llevar esto acabo se anima, que lo intente."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
